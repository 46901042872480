import { render, staticRenderFns } from "./default.vue?vue&type=template&id=d2e6b9c4&scoped=true&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&id=d2e6b9c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2e6b9c4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/codebuild/output/src3999815359/src/frontend/components/Logo.vue').default,SearchWidget: require('/codebuild/output/src3999815359/src/frontend/components/search/SearchWidget.vue').default,UserWidget: require('/codebuild/output/src3999815359/src/frontend/components/UserWidget.vue').default,NotificationsWidget: require('/codebuild/output/src3999815359/src/frontend/components/notifications/NotificationsWidget.vue').default,Footer: require('/codebuild/output/src3999815359/src/frontend/components/Footer.vue').default,SyncIndicator: require('/codebuild/output/src3999815359/src/frontend/components/SyncIndicator.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VContainer,VMain,VRow})
