import { render, staticRenderFns } from "./settings.vue?vue&type=template&id=254b74fe&scoped=true&"
import script from "./settings.vue?vue&type=script&lang=ts&"
export * from "./settings.vue?vue&type=script&lang=ts&"
import style0 from "./settings.vue?vue&type=style&index=0&id=254b74fe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "254b74fe",
  null
  
)

/* custom blocks */
import block0 from "./settings.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/codebuild/output/src3999815359/src/frontend/components/Logo.vue').default,SyncIndicator: require('/codebuild/output/src3999815359/src/frontend/components/SyncIndicator.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VApp,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VMain,VNavigationDrawer})
